import axiosIns from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDailyReportHistory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/daily-reports-history`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDailyReportHistorySingle(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/daily-reports-history/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProfiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/tender-profiles`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllProfiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/tender-profiles-list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProfile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/tender-profiles`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`${process.env.VUE_APP_API_URL}/tender-profiles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProfile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/tender-profiles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTenders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/tenders`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMyTenders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/user/tenders`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTender(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/tenders/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTenderReports(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/tender-reports`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDashboardTenders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/user/recent-tenders`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
