import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import clientsStoreModule from '@/views/pages/client/clientsStoreModule'
import userStoreModule from '@/views/pages/user/userStoreModule'
import tendersStoreModule from '@/views/pages/tenders/tendersStoreModule'
import cpvStoreModule from '@/views/pages/tenders/cpvStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    clients: clientsStoreModule,
    user: userStoreModule,
    tenders: tendersStoreModule,
    cpvs: cpvStoreModule,
  },
  strict: process.env.DEV,
})
