export const getAbilitiesByRole = user => {
  const ability = []
  if (user && user.roles[0].name === 'Admin') {
    ability.push(
      {
        subject: 'all',
        action: 'manage',
      },
    )
  } else if (user && user.roles[0].name === 'TestUser') {
    ability.push({
      subject: 'profile',
      action: 'manage',
    })

    ability.push({
      subject: 'my-tenders',
      action: 'view',
    })

    ability.push({
      subject: 'tenders-search',
      action: 'view',
    })

    ability.push(
      {
        subject: 'dashboard',
        action: 'view',
      },
    )
  } else if (user && user.roles[0].name === 'TenderViewer') {
    ability.push(
      {
        subject: 'profile',
        action: 'manage',
      },
    )

    ability.push({
      subject: 'my-tenders',
      action: 'view',
    })

    ability.push(
      {
        subject: 'dashboard',
        action: 'view',
      },
    )
  } else if (user && user.roles[0].name === 'TenderSearcher') {
    ability.push(
      {
        subject: 'profile',
        action: 'manage',
      },
    )

    ability.push({
      subject: 'tenders-search',
      action: 'view',
    })

    ability.push({
      subject: 'my-tenders',
      action: 'view',
    })

    ability.push(
      {
        subject: 'dashboard',
        action: 'view',
      },
    )
  } else {
    ability.push(
      {
        subject: 'all',
        action: 'read',
      },
    )
  }

  return ability
}
