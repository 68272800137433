import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import jwt from 'jwt-decode'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)

  try {
    const { exp } = jwt(token)

    if (token && Date.now() >= exp * 1000) {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      return false
    }

    return true
  } catch (e) {
    if (e.message == 'Invalid token specified') {
      return false
    }

    throw e
  }
}

export const getUserData = () => {
  if (!store.getters['user/getCurrentUser']) {
    store.dispatch('user/fetchLoggedInUser')
      .then(() => store.getters['user/getCurrentUser'])
  } else {
    return store.getters['user/getCurrentUser']
  }

  return null
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole && (userRole.name === 'admin' || userRole.name === 'user')) return { name: 'dashboard-default' }

  return { name: 'auth-login' }
}
