import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    user: null,
    roles: null,
    permissions: null,
  },
  getters: {
    async getCurrentUser(state) { return state.user },
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.user = user
    },
    SET_CURRENT_USER_ROLES(state, roles) {
      state.roles = roles
    },
  },
  actions: {
    async fetchLoggedInUser({ commit }) {
      const response = await axiosIns
        .get(`${process.env.VUE_APP_API_URL}/user/me`)

      commit('SET_CURRENT_USER', response.data.user)
      commit('SET_CURRENT_USER_ROLES', response.data.user.roles)
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/users`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${process.env.VUE_APP_API_URL}/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/users`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`${process.env.VUE_APP_API_URL}/users/${userData.id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/change-password`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`${process.env.VUE_APP_API_URL}/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    forgotPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/user/forgot-password`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    resetPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/user/reset-password-token`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    activateAccount(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/user/activate-account`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    verifyCompanyData(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`${process.env.VUE_APP_API_URL}/client/verify`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
