export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/activate-account',
    name: 'activate-account',
    component: () => import('@/views/pages/authentication/ActivateAccount.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/company-verified',
    name: 'company-verified',
    component: () => import('@/views/pages/authentication/CompanyVerified.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',

    },
  },
  // {
  //   path: '/pages/miscellaneous/under-maintenance',
  //   name: 'misc-under-maintenance',
  //   component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/error',
  //   name: 'misc-error',
  //   component: () => import('@/views/pages/miscellaneous/Error.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      resource: 'profile',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users',
    name: 'users-list',
    component: () => import('@/views/pages/user/users-list/UsersList.vue'),
    meta: {
      resource: 'users',
      action: 'manage',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/pages/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'users',
      action: 'manage',
      pageTitle: 'Users',
      navActiveLink: 'users-list',
      breadcrumb: [
        {
          text: 'Users',
          to: { name: 'users-list' },
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- CLIENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/clients',
    name: 'clients-list',
    component: () => import('@/views/pages/client/clients-list/ClientsList.vue'),
    meta: {
      resource: 'clients',
      action: 'manage',
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clients/:id',
    name: 'clients-edit',
    component: () => import('@/views/pages/client/clients-edit/ClientEdit.vue'),
    meta: {
      resource: 'clients',
      action: 'manage',
      pageTitle: 'Clients',
      navActiveLink: 'clients-list',
      breadcrumb: [
        {
          text: 'Clients',
          to: { name: 'clients-list' },
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tender-profiles',
    name: 'tender-profiles-list',
    component: () => import('@/views/pages/tenders/profile/ProfilesList.vue'),
    meta: {
      resource: 'tender-profile',
      action: 'manage',
      pageTitle: 'Profiles',
      breadcrumb: [
        {
          text: 'Tender profiles',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tender-profiles/add',
    name: 'tender-profile-add',
    component: () => import('@/views/pages/tenders/profile/ProfileNew.vue'),
    meta: {
      resource: 'tender-profile',
      action: 'manage',
      pageTitle: 'Add profile',
      navActiveLink: 'tender-profiles-list',
      breadcrumb: [
        {
          text: 'Tender profiles',
          to: { name: 'tender-profiles-list' },
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tender-profiles/edit/:id',
    name: 'tender-profile-edit',
    component: () => import('@/views/pages/tenders/profile/ProfileEdit.vue'),
    meta: {
      resource: 'tender-profile',
      action: 'manage',
      pageTitle: 'Edit profile',
      navActiveLink: 'tender-profiles-list',
      breadcrumb: [
        {
          text: 'Tender profiles',
          to: { name: 'tender-profiles-list' },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-tenders',
    name: 'my-tenders-list',
    component: () => import('@/views/pages/tenders/tenders-list/MyTendersList.vue'),
    meta: {
      pageTitle: 'My tenders',
      navActiveLink: 'my-tenders-list',
      resource: 'my-tenders',
      action: 'view',
      breadcrumb: [
        {
          text: 'My tenders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tenders',
    name: 'tenders-list',
    component: () => import('@/views/pages/tenders/tenders-list/TendersList.vue'),
    meta: {
      pageTitle: 'Tenders search',
      navActiveLink: 'tenders-list',
      resource: 'tenders-search',
      action: 'view',
      breadcrumb: [
        {
          text: 'Tenders search',
          active: true,
        },
      ],
    },
  },
  {
    path: '/daily-reports',
    name: 'daily-reports',
    component: () => import('@/views/pages/tenders/tender-reports-list/TenderReportsList.vue'),
    meta: {
      pageTitle: 'Daily reports',
      navActiveLink: 'tenders-list',
      breadcrumb: [
        {
          text: 'Tenders ',
          to: { name: 'tenders-list' },
        },
        {
          text: 'Daily reports ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/daily-reports-history',
    name: 'daily-reports-history',
    component: () => import('@/views/pages/notification-history/NotificationHistoryList.vue'),
    meta: {
      resource: 'daily-reports-history',
      action: 'view',
      pageTitle: 'Email history',
      breadcrumb: [
        {
          text: 'Email history',
          active: true,
        },
      ],
    },
  },
  {
    path: '/daily-reports-history/:id',
    name: 'daily-reports-history-view',
    component: () => import('@/views/pages/notification-history/NotificationHistoryView.vue'),
    meta: {
      resource: 'daily-reports-history',
      action: 'view',
      navActiveLink: 'daily-reports-history',
      pageTitle: 'Email history details',
      breadcrumb: [
        {
          text: 'Email history ',
          to: { name: 'daily-reports-history' },
        },
        {
          text: 'Email history details',
          active: true,
        },
      ],
    },
  },
]
