import { Ability } from '@casl/ability'
import useJwt from '@/auth/jwt/useJwt'
import { getAbilitiesByRole } from '@/libs/acl/abilityMapper'
import { initialAbility } from './config'

let existingAbility = null
try {
  const user = useJwt.getUserFromToken()

  existingAbility = user ? getAbilitiesByRole(user) : null
} catch (e) {
  console.log(e)
}

export default new Ability(existingAbility || initialAbility)
