export default [

  {
    path: '/dashboard',
    name: 'dashboard-default',
    component: () => import('@/views/dashboard/default/Default.vue'),
    meta: {
      pageTitle: 'Dashboard',
      resource: 'dashboard',
      action: 'view',
    },
  },
]
