import Vue, { getCurrentInstance } from 'vue'
import axios from 'axios'

const axiosIns = axios.create({})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken')
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
